import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 10-10-10 to a 10RM`}</p>
    <p>{`Ball Hamstring Curls 10-10-10`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`100ft OH Walking Lunges (45/25`}{`#`}{`)`}</p>
    <p>{`80 Wall Balls (20/14)`}</p>
    <p>{`60 Burpees`}</p>
    <p>{`40 T2B`}</p>
    <p>{`20 HSPU’s`}</p>
    <p>{`10 Bear Complex (115/75)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      